import { Alert, AlertTitle, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import rehypeAttrs from "rehype-attr";
import rehypeRaw from "rehype-raw";
import TextInput from "../../../components/form/TextInput";
import LinkRenderer from "../../../components/LinkRenderer";
import { MolliePayment } from "../../../state/payments";

const MollieFields = ({ values }: { values: Omit<MolliePayment, "id"> }) => {
  const { t } = useTranslation();
  return (
    <>
      <Alert
        severity="info"
        sx={{
          mt: 4,
          "& a": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        <AlertTitle>{t("setup.payment.mollie.setupInfoTitle")}</AlertTitle>
        <ReactMarkdown
          components={{ a: LinkRenderer }}
          rehypePlugins={[rehypeRaw, [rehypeAttrs, { properties: "attr" }]]}
        >
          {t("setup.payment.mollie.setupInfo", {
            joinArrays: "  \n",
          })}
        </ReactMarkdown>
      </Alert>

      <Paper sx={{ p: 2, mt: 2 }}>
        <Typography variant="h2">
          {t("setup.payment.mollie.generalTitle")}
        </Typography>

        <TextInput
          key="options.apiKey"
          name="options.apiKey"
          type="password"
          title={t("setup.payment.mollie.apiKey") + ":"}
        />
      </Paper>
    </>
  );
};

export default MollieFields;
